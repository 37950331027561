import { BLOCKS, MARKS } from "@contentful/rich-text-types"

import Head from "../components/head"
import Layout from "../components/layout"
import React from "react"
import blogPostStyle from "../styles/blogPost.module.scss"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { graphql } from "gatsby"

export const query = graphql`
  query($id: String!) {
    contentfulBlogPost(id: { eq: $id }) {
      title
      publishedDate(formatString: "Do, MMMM, YYYY")
      body {
        json
      }
    }
  }
`

const Blog = props => {
  const options = {
    renderMark: {
      [MARKS.CODE]: text => <code className={blogPostStyle.code}>{text}</code>,
    },
    renderNode: {
      "embedded-asset-block": node => {
        const alt = node.data.target.fields.title["en-US"]
        const url = node.data.target.fields.file["en-US"].url
        return <img alt={alt} src={url} />
      },
      hyperlink: node => {
        if (node.data.uri.indexOf("youtube.com") !== -1) {
          return (
            <div class={blogPostStyle.YoutubeContainer}>
              <iframe
                title={node.data.uri}
                class={blogPostStyle.ResponsiveFrame}
                src={node.data.uri}
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          )
        } else {
          return <a href={node.data.uri}>{node.data.uri}</a>
        }
      },
      [BLOCKS.HEADING_2]: (node, children) => {
        return <h2 className={blogPostStyle.h2}>{children}</h2>
      },
      [BLOCKS.HEADING_1]: (node, children) => {
        return <h1>{children}</h1>
      },
      [BLOCKS.PARAGRAPH]: (node, children) => {
        return <p className={blogPostStyle.text}>{children}</p>
      },
    },
    renderText: text => {
      return text.split("\n").reduce((children, textSegment, index) => {
        console.log(textSegment)
        return [...children, index > 0 && <br key={index} />, textSegment]
      }, [])
    },
  }

  return (
    <Layout>
      <div className={blogPostStyle.container}>
        <Head title={props.data.contentfulBlogPost.title} />
        <h1>{props.data.contentfulBlogPost.title}</h1>
        <p>{props.data.contentfulBlogPost.publishedDate}</p>
        <div>
          {documentToReactComponents(
            props.data.contentfulBlogPost.body.json,
            options
          )}
        </div>
      </div>
    </Layout>
  )
}

export default Blog
